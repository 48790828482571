const Phaser = require('phaser');

export class Player {
    constructor(scene, sprite, tilePos) {
        this.gameScene = scene;
        this.sprite = sprite;
        this.sprite.setOrigin(0.5, 1);
        this.sprite.setPosition(tilePos.x, tilePos.y);
        this.light = this.gameScene.lights.addLight(tilePos.x, tilePos.y, 400).setScrollFactor(0).setColor(0xffb347).setIntensity(2);
        //this.sprite.setFrame(55);
    }

    getPosition() {
        return this.sprite.getBottomCenter();
    }

    setPosition(position) {
        this.sprite.setPosition(position.x, position.y);
        this.sprite.x = position.x;
        this.sprite.y = position.y;
        // this.light.setPosition(position.x, position.y);
        // this.light.x = position.x;
        // this.light.y = position.y;
    }
}

// const player = this.add.sprite(400, 300, 'player');
//         player.setOrigin(0.5, 1);
//         player.setPipeline('Light2D');
//         player.setScale(4);
