import {Direction} from "./direction";

const Phaser = require('phaser');

export class GridPhysics {
    constructor(player) {
        this.player = player;
        this.movementDirection = Direction.NONE;
        this.speedPixelsPerSecond = 60;
    }

    movePlayer(direction) {
        if (!this.isMoving()) {
            this.startMoving(direction);
        }
    }

    isMoving() {
        return this.movementDirection != Direction.NONE;
    }

    startMoving(direction) {
        this.movementDirection = direction;
    }

    stopMoving() {
        this.movementDirection = Direction.NONE;
    }

    update(delta) {
        if (this.isMoving()) {
            this.updatePlayerPosition(delta);
        }
    }

    updatePlayerPosition(delta) {
        const pixelsToWalkThisUpdate = this.getPixelsToWalkThisUpdate(delta);

        const directionVec = this.movementDirectionVectors(this.movementDirection);

        const movementDistance = directionVec.multiply(
            new Phaser.Math.Vector2(pixelsToWalkThisUpdate)
        );

        const newPlayerPos = this.player.getPosition().add(movementDistance);

        this.player.setPosition(newPlayerPos);
        this.stopMoving();
    }

    getPixelsToWalkThisUpdate(delta) {
        const deltaInSeconds = delta / 1000;
        return this.speedPixelsPerSecond * deltaInSeconds;
    }

    movementDirectionVectors(direction) {
        switch (direction) {
            case Direction.UP:
                return Phaser.Math.Vector2.UP;
            case Direction.DOWN:
                return Phaser.Math.Vector2.DOWN;
            case Direction.LEFT:
                return Phaser.Math.Vector2.LEFT;
            case Direction.RIGHT:
                return Phaser.Math.Vector2.RIGHT;
        }
    }
}