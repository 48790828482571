import WallSprite from "../assets/wall_center.png"
import WizzardSprite from "../assets/npc_wizzard.png"
import FloorTestSprite from "../assets/floor_test.png"
import {Player} from "../objects/player";
import {GridPhysics} from "../utils/grid-physics";
import {GridControls} from "../utils/grid-controls";
const Phaser = require('phaser');

export default class Example extends Phaser.Scene
{
    constructor() {
        super({key: 'Example'});
    }

    preload ()
    {
        //this.load.image('brick', [ 'assets/normal-maps/brick.jpg', 'assets/normal-maps/brick_n.png' ]);
        this.load.spritesheet('floor', FloorTestSprite, {frameWidth: 800, frameHeight: 600});
        this.load.spritesheet('player', WizzardSprite, {frameWidth: 16, frameHeight: 16});
    }

    create ()
    {
        const floor = this.add.tileSprite(0, 0, 800, 600, 'floor');
        floor.setOrigin(0, 0);
        floor.setPipeline('Light2D');

        // const player = this.add.sprite(400, 300, 'player');
        // player.setOrigin(0.5, 1);
        // player.setPipeline('Light2D');
        // player.setScale(4);

        const playerSprite = this.add.sprite(0, 0, "player");
        // playerSprite.setDepth(2);
        // playerSprite.setScale(1);
        playerSprite.setPipeline('Light2D');
        this.cameras.main.startFollow(playerSprite);
        this.cameras.main.roundPixels = true;
        const player = new Player(this, playerSprite, new Phaser.Math.Vector2(400, 300));

        // this.light = this.lights.addLight(0, 0, 400).setScrollFactor(0).setColor(0xffb347).setIntensity(2);
        //this.light = this.lights.addLight(0, 0, 60).setScrollFactor(0).setColor(0x0000E6).setIntensity(2);

        //this.lights.enable().setAmbientColor(0x555555);

        this.lights.enable();

        this.gridPhysics = new GridPhysics(player);
        this.gridControls = new GridControls(
          this.input,
          this.gridPhysics
        );

        this.input.on('pointermove', pointer =>
        {

            // this.light.x = pointer.x;
            // this.light.y = pointer.y;

        });

        // this.input.on('pointerdown', pointer =>
        // {
        //
        //     if (this.lights.active)
        //     {
        //         this.lights.disable();
        //     }
        //     else
        //     {
        //         this.lights.enable();
        //     }
        //
        // });

        //this.add.image(300, 400, 'phaser').setPipeline('Light2D');


        //this.lights.addLight(0, 400, 100).setColor(0xffff00).setIntensity(3.0);

    }

    update(_time, delta) {
        this.gridControls.update();
        this.gridPhysics.update(delta);
    }
}
