import Example from './scenes/game-scene'

const Phaser = require('phaser');

// game.js
const config = {
  type: Phaser.AUTO,
  width: 800,
  height: 600,
  scene: [
      Example,
  ]
};

// let config = {
//     type: Phaser.WEBGL,
//     scene: [
//         Example,
//     ],
//     scale: {
//         mode: Phaser.Scale.RESIZE,
//     },
//     // banner: false
//     // disableContextMenu: true,
//     // inputTouch: true,
// }

const game = new Phaser.Game(config);